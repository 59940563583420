@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

html {
  scroll-behavior: smooth;
}

* {
  font-family: "Ubuntu", sans-serif;
}

.client-logo {
  @apply flex flex-wrap gap-8 justify-center 
}
.client-logo img{
  @apply sm:h-16 h-12 object-contain
}

